import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@config/axiosConfig.js'
import '@assets/css/css-vars.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import '@assets/css/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { VueMasonryPlugin } from 'vue-masonry'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(ElementPlus).use(store).use(router).use(VueMasonryPlugin).mount('#app')

var deviceName = navigator.userAgent.toLowerCase()
if (/android|iphone|webos|ipod|blackberry/.test(deviceName)) {
  app.config.globalProperties.maxTagCount = 2
  app.config.globalProperties.waterfallWidth = 175
  app.config.globalProperties.waterfallStyle = {
    overflow: 'hidden',
    margin: '0px auto',
    width: '376px',
  }
  // app.config.globalProperties.width = {
  //   width: '100%',
  // }
  app.config.globalProperties.videoWidthHeighut = {
    width: '300px',
    height: '200px',
  }
} else {
  app.config.globalProperties.maxTagCount = 5
  app.config.globalProperties.waterfallWidth = 372
  app.config.globalProperties.waterfallStyle = {
    overflow: 'hidden',
    margin: '0px auto',
    width: '1537px',
  }
  app.config.globalProperties.width = {}
  app.config.globalProperties.videoWidthHeighut = {
    width: '880px',
    height: '500px',
  }
}
