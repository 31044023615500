import axios from 'axios'
import { ElMessage } from 'element-plus'
// 根据环境设定请求后端 url 地址
//axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
axios.defaults.baseURL = 'https://uni.huxixi.cn'
// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    config.headers = {
      token: localStorage.getItem('token'),
    }
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    response = response.data
    if (response.code == 403) {
      localStorage.clear()
    }
    if (response.code !== 200) {
      ElMessage.error({ message: response.msg })
      return Promise.reject(response)
    } else {
      return response
    }
  },
  function (error) {
    // 对响应错误做点什么
    ElMessage.error({ message: error })
    return Promise.reject(error)
  }
)
