import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@views/HomeView.vue'),
  },
  {
    path: '/hxx',
    name: 'about',
    component: () => import('@views/AboutView.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  // 判断有没有登录
  // if (!localStorage.getItem('token')) {
  //   if (to.name == 'login') {
  //     next()
  //   } else {
  //     router.push('login')
  //   }
  // } else {
  //   next()
  // }
  document.title = 'karaxの导航'
  document
    .querySelector('link[rel="icon"]')
    .setAttribute('href', '../assets/logo.jpg')
  // console.log(1)
  next()
})

export default router
