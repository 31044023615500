<template>
  <div class="common-layout">
    <el-container>
      <el-header class="index-nav" style="background-color: var(--header-bg-color);height: 50px;">
        <el-menu id="nav-menu" :default-active="0" mode="horizontal" :ellipsis="false" background-color="#fff"
          text-color="#484848" active-text-color="#484848" @select="handleSelect">
          <!-- <div class="flex-grow-1" /> -->
          <el-menu-item index="0" style="border-bottom: 0;" @click="router.push('/')">
            <img style="width: 40px;border-radius: 8px;" src="./assets/logo.jpg" alt="logo" />
            <div id="logo-text">&nbsp;karaxの导航</div>
          </el-menu-item>
          <div style="flex-grow: 1;" />
          <el-menu-item index="1">
            <el-switch v-model="toggleDarkModel" :active-action-icon="Moon" :inactive-action-icon="Sunny" @click="change"
              @beforeChange="beforeChange">
            </el-switch>
          </el-menu-item>
          <el-sub-menu index="2">
            <template #title>karax</template>
            <el-menu-item index="2-1">item one</el-menu-item>
            <el-menu-item index="2-2">item two</el-menu-item>
            <el-menu-item index="2-3">item three</el-menu-item>
          </el-sub-menu>
          <!-- <div class="flex-grow-1" /> -->
        </el-menu>
      </el-header>
      <el-main class="index-main">
        <el-scrollbar style="height: calc(100vh - 80px);">
          <router-view />
        </el-scrollbar>
      </el-main>
      <div id="beian">
        <BeiAn ipc="浙ICP备19047323号" num="2" />
      </div>
    </el-container>
  </div>
</template>

<script setup>
import BeiAn from '@components/BeiAn.vue'
import { ref, reactive } from 'vue'
import { useRoute, useRouter } from "vue-router"
import { useDark, useToggle } from '@vueuse/core'
import { Sunny, Moon } from '@element-plus/icons-vue'
const router = useRouter()

const isDark = useDark({
  storageKey: "theme",
  selector: "html",
  attribute: "class",
  valueDark: "dark",
  valueLight: "light",
});
const toggleDarkModel = ref(isDark.value);
const toggleDark = useToggle(isDark)


const change = (event) => {
  const x = event.clientX;
  const y = event.clientY;
  const endRadius = Math.hypot(
    Math.max(x, innerWidth - x),
    Math.max(y, innerHeight - y)
  );

  // 兼容性处理
  if (!document.startViewTransition) {
    toggleDark();
    return;
  }
  const transition = document.startViewTransition(async () => {
    toggleDark();
  });

  transition.ready.then(() => {
    const clipPath = [
      `circle(0px at ${x}px ${y}px)`,
      `circle(${endRadius}px at ${x}px ${y}px)`,
    ];
    console.log(isDark.value);
    document.documentElement.animate(
      {
        clipPath: isDark.value ? [...clipPath].reverse() : clipPath,
      },
      {
        duration: 400,
        easing: "ease-in",
        pseudoElement: isDark.value
          ? "::view-transition-old(root)"
          : "::view-transition-new(root)",
      }
    );
  });
};

const activeIndex = ref('1')
const handleSelect = (key, keyPath) => {
  console.log(key, keyPath)
  console.log(activeIndex.value)
}
</script>

<style>
#app {
  font-family: Arial, Helvetica, sans-serif;
}

#nav {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--header-bg-color);
}

#nav-menu {
  width: 100%;
  height: 50px;
  --el-menu-bg-color: var(--header-bg-color) !important;
  --el-menu-hover-bg-color: var(--header-bg-color) !important;
  --el-menu-text-color: var(--header-font-color) !important;
}

#logo-text {
  font-size: 20px;
  color: var(--header-font-color);
  font-weight: 400;
}

.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 0 !important;
}

.el-menu-item [class^=el-icon] {
  margin-right: 0px !important;
  font-size: 14px !important;
}

.index-main {
  background-color: var(--body-bg-color);
  padding: 0px 0px !important;
}

#beian {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  background-color: var(--footer-bg-color) !important;
}
</style>
