<template>
  <div class="beian">
    <img style="width: 16px; height: 16px" src="@assets/beian/beian.png" alt="备案标识" />
    <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33042102000804&token=2be051c1-22dd-44ec-9f4b-d41155f2d855"
      target="_blank" rel="nofollow noopener noreferrer">{{ ipc }}</a>&nbsp;
    <a href="http://beian.miit.gov.cn" target="_blank" rel="nofollow noopener noreferrer">{{ ipc + "-" + num }}</a>
  </div>
</template>

<script>
export default {
  name: 'BeiAn',
  props: {
    ipc: String,
    num: String,
  },
}
</script>

<style scoped>
.beian {
  background-color: var(--idx-bg-color);
  font-size: 12px;
  display: flex;
  align-items: center;
}

a {
  color: var(--footer-font-color)
}
</style>
